.CreateBillPanel {
    // display: grid;


    .Forms {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        margin: 0 auto;
        row-gap: 2rem;
    }

    .Slider {
        width: 100%;
        text-align: left;

        h4 {
            // margin: 0 0 16px;
        }

        .ant-slider-mark-text {
            color: #afafaf;
            transition: color 0.3s;
            padding: 4px;
            margin-top: 4px;
            border-radius: var(--border-radius);
            background-color: #ffffff29;
            filter: brightness(0.85);
        }

        .ant-slider-mark-text.ant-slider-mark-text-active {
            color: #fff;
            filter: brightness(1);
        }
    }
}