.status__confirms {
    position: relative;
    display: flex;


    .confirm {
        flex-direction: row;
        justify-self: center;
        padding: 16px;
        align-items: center;
        display: flex;

        &__icon {
            align-items: center;
            -webkit-animation-duration: 3s;
            animation-duration: 3s;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-name: ckw;
            animation-name: ckw;
            color: var(--success);
            display: flex;
            justify-content: center;
            margin-right: 16px;
            -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
            font-size: 2.5rem;
        }

        &__text {
            color: var(--success);
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;

            .text__title {
                color: var(--contentSecondary);
                font-size: 16px;
                font-weight: 400;
                letter-spacing: -.12px;
                line-height: 24px;
                overflow: revert;
                text-overflow: unset;
                display: block;
                width: 95%;
            }
        }
    }


    .confirms__notification {
        @media (max-width: 768px) {
            right: 16px;
        }

        align-items: center;
        background-color: var(--backgroundApp);
        border: none;
        border-radius: 100px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, .04),
        0 0 6px rgba(0, 0, 0, .04);
        color: var(--primary);
        cursor: pointer;
        display: flex;
        height: 40px;
        width: 40px;
        justify-content: center;
        outline: none;
        padding: 0;
        position: absolute;
        right: -20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        transition: all .3s ease;
        font-size: 1.3rem;

        &:hover {
            background-color: var(--primary);
            color: var(--backgroundApp);
        }

        &:active {
            background-color: var(--primary);
            color: var(--backgroundApp);
        }
    }
}

@keyframes ckw {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}