.MenuButton {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    row-gap: 0.4rem;
    transition: 0.2s ease-in-out;
    // убрать возможность выделения текста
    -webkit-user-select: none;
    text-align: center;

    .circle {
        width: 2.7rem;
        height: 2.7rem;
        background-color: var(--bg-secondary);
        color: #fff;
        font-size: 1.5rem;
        cursor: pointer;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        font-family: 'Montserrat', sans-serif;
        color: var(--fg-secondary);
        font-size: 0.8rem;
        // отключить выделение текста
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
    }

    &:active {
        // затемнение
        filter: brightness(0.85);
    }
}