.Deposit {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    row-gap: 2rem;
    padding-bottom: 4rem;

    .header {
        row-gap: .9rem;
        font-family: 'Montserrat', sans-serif;
        margin-top: -1rem;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .img {
            transform: rotate(180deg);
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        row-gap: 1rem;

        .QR,
        .Address {
            position: relative;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            padding: 1rem;
            border-radius: var(--border-radius);
            background-color: var(--bg-secondary);
            row-gap: .6rem;


            .desc {
                width: 100%;
                text-align: left;
                font-family: 'Montserrat', sans-serif;
                font-size: 1rem;
                color: var(--fg-primary);
            }

            .address {
                color: var(--fg-secondary);
                // перенос текста на новую строку
                word-break: break-all;
                font-family: 'Montserrat', sans-serif;
                text-align: left;
                font-size: 0.8rem;
                padding-bottom: 3rem;
                cursor: pointer;

                &:hover {
                    // затемнение 
                    filter: brightness(0.85);
                }

                &:active {
                    animation: 0.2s ease-out 0s 1 blink;
                }
            }

            .panel {
                position: absolute;
                left: 0;
                width: 100%;
                border-top: 2px solid var(--fg-primary);
                bottom: 0;
                display: grid;
                // 2 колонки
                grid-template-columns: 1fr 1fr;

                //указать минимальный размер шрифта и максимальный
                font-size: 85%;


                & div {
                    display: flex;
                    flex-direction: row;
                    row-gap: 1rem;
                    justify-content: center;
                    align-items: center;
                    transition: all ease-out .3s;
                    cursor: pointer;
                    padding: 0.5rem;

                    & span[role="img"] {
                        font-size: 1.2rem;
                        padding-right: 0.3rem;
                    }

                    &:active {
                        // затемнение 
                        background-color: var(--bg-primary);
                        // filter: brightness(0.85);
                    }
                }

                // первый div
                & div:first-child {
                    border-right: 2px solid var(--fg-primary);
                }

                // второй div
            }
        }
    }

}