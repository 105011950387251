
.CashBack {
    font-family: 'Courier New', Courier, monospace;
    display: inline-block;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    column-gap: .6rem;

    &__wrapper {
        background: var(--secondary);
        border-radius: 78px;
        gap: 4px;
        padding: 4px 10px;
        align-items: center;
        display: inline-block;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }


    &__logo {
        display: flex;
        background: var(--secondary);
        border-radius: 46px;
        font-size: 1.7rem;
    }

    &__amount {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
    }

    &__faq {
        display: flex;
        // color: var(--primary);
    }
}