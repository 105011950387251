@use 'sass:math';
$containerWidth: 1150;
$containerPadding: 40;
$containerSidePadding: math.div($containerPadding, 2);
$breakpoint1450: 1449.98+px;
$breakpoint1200: 1199.98+px;
$breakpoint992: 991.98+px;
$breakpoint768: 767.98+px;
$breakpoint480: 479.98+px;
$breakpoint375: 374.98+px;
$breakpoint300: 299.98+px;

// Burger Menu
$burgerBreakpoint: $breakpoint992;
$burgerColor: 'black';
$burgerActiveColor: 'black';

// Fonts
$fontMain: sans-serif;
$fontSecondary: '';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;1,200&family=Oswald:wght@700&display=swap');



.section__footer {
    padding: 65px 0px;
    background: #1E1E1E;

    width: 100%;

    & .footer {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: $breakpoint480) {
            align-items: flex-start;
        }

        &__svg {
            @media (max-width: $breakpoint480) {
                max-width: 300px;
            }
        }

        &__ul {
            margin: 11px 0px 20px 0px;

            &-li {
                display: flex;
                align-items: center;
                gap: 20px;
                margin-bottom: 10px;
            }

            &-link {
                font-family: 'Montserrat', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.005em;
                color: #FFFFFF;
                transition: all ease .3s;

                &:hover {
                    color: #81b245;
                }
            }

            &-link2 {
                font-family: 'Montserrat', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.005em;
                color: #FFFFFF;
                transition: all ease .3s;

            }

        }

        &__menu {
            display: flex;
            align-items: center;
            text-align: center;
            gap: 30px;

            @media (max-width: $breakpoint480) {
                flex-direction: column;
                text-align: left;
                align-items: flex-start;
            }

            &-link {

                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                text-align: center;
                letter-spacing: 0.005em;
                color: #FFFFFF;
                transition: all ease .3s;

                &:hover {
                    color: #81b245;
                }

            }

        }

        &__text {
            font-family: 'Montserrat';
            font-style: italic;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.005em;
            color: #FFFFFF;
            text-align: center;
            margin-top: 24px;
        }


    }

}