.loader {
    height: 100%;
    width: 100%;
    font-size: 3rem;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;


    &--icon {
        display: flex;
        justify-content: center;
        animation: loop 1s infinite linear;
    }
}

@keyframes loop {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}