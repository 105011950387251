.payment-details {
    display: flex;
    flex-direction: column;
    padding: 0 32px 16px;

    &__wrapper {
        align-items: center;
        display: flex;
        gap: 8px;
        margin-bottom: 16px;
    }

    &__amount_wrapper {
        display: flex;
        flex-wrap: wrap;
        // font-size: 3rem;
        // размер текста максимальный минимальный размер
        font-size: clamp(2rem, 5vw, 3rem);
        flex-direction: column;
        align-items: flex-start;

        .payment-details__left,
        .payment-details__total {
            display: flex;
        }

        .payment-details__total {
            font-size: 2rem;
            color: var(--borderPrimarySolid);
            margin-bottom: -18px;
            text-decoration: line-through;
            text-decoration-color: #ffffff99;
        }

        .payment-details__amount {
            font-family: 'Montserrat', sans-serif;
            margin-right: 8px;
        }
    }

    .payment-details__fee {
        align-items: center;
        color: var(--contentSecondary);
        display: flex;
        gap: 8px;
        margin: 16px 0;
    }

    .copy-icon {
        align-items: center;
        background: var(--backgroundCopyIcon);
        border-radius: 40px;
        box-shadow: var(--sElevation_3);
        color: var(--contentPrimary);
        cursor: pointer;
        display: flex;
        flex-shrink: 0;
        height: 44px;
        justify-content: center;
        transition: all .3s ease;
        width: 44px;

        &:active {
            transform: scale(.9);
        }

        &:hover {
            background: var(--contentPrimary);
            color: var(--backgroundCopyIcon);
            box-shadow: none;
        }
    }

    .status-redirect {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;


        &__link {
            display: flex;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            align-content: center;
            flex-wrap: nowrap;

            flex-direction: row;
            color: var(--primary);
            background-color: var(--backgroundCopyIcon);
            padding: 10px 24px;
            border-radius: var(--border-radius);
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            min-width: 75%;
            text-align: center;
            // text-decoration: underline;
            cursor: pointer;
            transition: all .1s ease;

            &:hover {
                box-shadow: var(--sElevation_3);
                background-color: var(--ton);
            }

            svg{
                // от 1rem до 2rem
                font-size: clamp(1rem, 6vw, 5rem);
            }
        }
    }
}