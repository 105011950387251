.content__info {
    background: var(--payform);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    // padding: 32px;

    .Header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 32px 32px 0;

        @media (max-width: 768px) {
            padding: 5px 40px 0;
            text-align: center;
            justify-content: center
        }



        &_logo {
            align-items: center;
            display: flex;
            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
            font-size: 28px;
            gap: 8px;
            letter-spacing: -.59px;
            line-height: 36px;

            svg {
                // font-size: 3rem;
                font-size: clamp(2rem, 6vw, 3rem);
            }
        }

        &_badges {}
    }
}