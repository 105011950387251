.DrawerPanel {
    border-radius: var(--border-radius);
    z-index: 1000;
    text-align: center;
    width: 100%;
    top: 3vh;
    min-height: 97vh;
    display: flex;
    position: absolute;
    left: 0;
    background-color: var(--bg-primary);
    transition: all ease-out .3s;
    transform: translateY(100%);
    height: auto;

    &.active {
        display: flex;
        transform: translateY(0) scale(1.03);
    }



    .context {
        width: 100%;
        padding: 0 var(--padding-primary);
        padding-top: 10px;

        .header {
            display: flex;

            .exitBtn {
                width: 2.4rem;
                height: 2.4rem;
                background-color: var(--bg-secondary);
                color: #fff;
                font-size: 1.2rem;
                cursor: pointer;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    // затемнение 
                    filter: brightness(0.85);
                }
            }
        }
    }
}