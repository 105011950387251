.status-container {
    padding: 0 32px;

    .status-hint {
        &[data-type="danger"] {
            background-color: var(--danger-lighter-alpha);

            .status-hint__icon {
                color: var(--danger);
            }
        }

        &[data-type="success"] {
            background-color: var(--success-lighter-alpha);

            .status-hint__icon {
                color: var(--success);
            }
        }


        grid-row-gap: 6px;
        grid-column-gap: 16px;
        border-radius: 12px;
        -webkit-column-gap: 16px;
        column-gap: 16px;
        display: grid;
        grid-template-columns: auto 1fr;
        padding: 16px;
        row-gap: 6px;

        &__icon {
            font-size: 1.5rem;
        }

        &__heading-text {
            color: var(--primary);
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
        }

        &__description {
            color: var(--primary);
            font-size: 16px;
            font-weight: 400;
            grid-column: 2;
            letter-spacing: -.12px;
            line-height: 24px;
            font-family: var(--font-main);
        }
    }
}