.paymentsActions {
    padding: 32px 16px;
    background-color: var(--Dark_bg1);
    border-radius: 0 0 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px;

    // если размер меньше 768
    @media (max-width: 768px) {
        padding: 22px 11px;
    }

    .checkout {
        background: var(--payformCheckout);
        border-radius: 16px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, .04), 0 0 6px rgba(0, 0, 0, .04);
        display: flex;
        flex-direction: column;
        width: 100%;

        &__pay {
            border-bottom: 1px solid var(--borderPrimarySolid);

            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 16px;

            &__btn {
                column-gap: 0.3rem;
                align-items: center;
                background: var(--payformCheckout);
                border-radius: var(--border-radius);
                box-shadow: var(--sElevation_3);
                color: var(--contentPrimary);
                cursor: pointer;
                display: flex;
                flex-shrink: 0;
                padding: 1rem 0px;
                justify-content: center;
                transition: all 0.3s ease;
                width: 100%;

                & img {
                    max-width: 32px;
                    max-height: 32px;
                    filter: drop-shadow(2px 4px 6px black)
                }

                &:hover {
                    transform: scale(1.01);
                    // box-shadow: var(--sElevation_3);
                }

                &:active {
                    background: var(--backgroundCopyIcon);
                    box-shadow: var(--sElevation_4);
                }
            }
        }

        &__info {
            border-bottom: 1px solid var(--borderPrimarySolid);
            display: flex;
            padding: 16px;

            @media (min-width: 768px) {
                column-gap: 1rem;
            }


            .qr-wrapper {
                background: none repeat scroll 0 0 transparent;
                border: 0;
                font-size: 100%;
                margin: 0;
                outline: 0 none;
                padding: 0;
                vertical-align: initial;
            }

            .info__recipient {
                padding: 4px 0;

                .recipient__title {
                    color: var(--textSecondary);
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: -.08px;
                    line-height: 20px;
                    margin-bottom: 2px;
                    text-align: left;

                }

                .recipient__address {
                    grid-gap: 4px;
                    align-items: center;
                    display: grid;
                    gap: 4px;
                    grid-template-columns: minmax(100px, auto) 1fr;
                    margin-bottom: 8px;

                    .address__text {
                        color: var(--contentPrimary);
                        display: block;
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: -.12px;
                        line-height: 24px;
                        overflow: hidden;
                        text-overflow: unset;
                        font: var(--font-primary);
                    }

                    .address__buttons {
                        align-items: center;
                        display: flex;
                        gap: 8px;
                        justify-content: end;
                        transition: all .1s ease;

                        >div {
                            &:hover {
                                cursor: pointer;
                                transform: scale(1.1);
                            }

                            &:active {
                                transform: scale(.9);
                            }
                        }
                    }
                }
            }

            .info__notification-alarm {
                color: var(--textSecondary);
                font-size: 14px;
                font-weight: 400;
                letter-spacing: -.08px;
                line-height: 20px;

                &-alert {
                    color: #ff3d4d;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: -.12px;
                    line-height: 24px;
                }

                .notification-alarm__email {
                    background: none;
                    border: none;
                    cursor: pointer;
                    margin-bottom: 2px;
                    outline: none;
                    padding: 0;
                    text-decoration: underline;
                    color: var(--textSecondary);
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: -.08px;
                    line-height: 20px;
                    padding-left: 5px;
                }
            }
        }


        &__status {
            display: flex;

            // max-width: 768
            @media (max-width: 768px) {
                flex-direction: column;
            }

            @media (min-width: 768px) {
                flex-direction: row;
            }


        }
    }

}