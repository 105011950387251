.MainMenu {
    // убрать выделение
    user-select: none;
    
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    // height: 5vh;
    display: grid;
    // 4 колонки
    grid-template-columns: repeat(4, 1fr);
    background-color: var(--bg-primary);

    &__button {
        background-color: var(--bg-primary);
        cursor: pointer;
        color: var(--fg-primary);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 0.4rem;
        padding: 1rem 0.4rem;
        border-radius: var(--border-radius);
        // padding-bottom: 0;

        &.active {
            color: var(--ac-primary);
        }

        &:active {
            // затемнение
            filter: brightness(0.85);
        }

        .icon {
            font-size: 1.8rem;
        }

        .desc {
            font-family: 'Montserrat', Courier, monospace;
            font-weight: 'bold';
            font-size: 0.9rem;
        }
    }
}