.AddressData {
    font-family: 'Montserrat', sans-serif;
    color: var(--fg-secondary);
    font-size: 0.8rem;
    // отключить выделение текста
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    span {
        font-family: 'Montserrat';
        font-size: 0.6rem;
    }
    // при нажатие сделать мерцание
    &:active {
        animation: 0.2s ease-out 0s 1 blink;
    }
}

@keyframes blink {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}