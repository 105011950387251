@use 'sass:math';
$containerWidth: 1150;
$containerPadding: 40;
$containerSidePadding: math.div($containerPadding, 2);
$breakpoint1450: 1449.98+px;
$breakpoint1200: 1199.98+px;
$breakpoint992: 991.98+px;
$breakpoint768: 767.98+px;
$breakpoint480: 479.98+px;
$breakpoint375: 374.98+px;
$breakpoint300: 299.98+px;

// Burger Menu
$burgerBreakpoint: $breakpoint992;
$burgerColor: 'black';
$burgerActiveColor: 'black';

// Fonts
$fontMain: sans-serif;
$fontSecondary: '';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;1,200&family=Oswald:wght@700&display=swap');

:root {
    --font-main: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;


    --payform: linear-gradient(255.05deg, #282828 3.88%, #232323);
    --payformCheckout: linear-gradient(255.05deg, #282828 3.88%, #232323);
    --Dark_bg1: #141414;
    --textSecondary: #747474;
    --borderPrimarySolid: hsla(0, 0%, 100%, .1);
    --danger-lighter-alpha: rgba(255, 61, 77, 0.239);
    --success-lighter-alpha: rgba(58, 194, 112, .24);
    --contentSecondary: #c5c5c5;

    --backgroundPrimaryFiat: #373737;
    --backgroundSecondary: #242424;
    --backgroundTertiary: #373737;
    --backgroundInverted: #fff;
    --backgroundApp: #242424;
    --backgroundExpiration: #141414;
    --backgroundCopyIcon: #2d2d2d;
    --backgroundPayForm: #242424;

    --bg-primary: --backgroundPrimaryFiat;
    --bg-secondary: --backgroundSecondary;
    --bg-quaternary: --backgroundInverted;
    --bg-tertiary: --backgroundTertiary;
    --fg-primary: #fff;
    --fg-secondary: #C5CBD6;
    --ac-primary: #45AEF5;

    --ton: #45AEF5;

    --sElevation_3: 0px 8px 16px hsla(0, 0%, 100%, .06), 0px 0px 10px hsla(0, 0%, 100%, .2);
    --contentSecondary: #c5c5c5;
    --contentPrimary: #fff;


    // Colors
    --primary: #fff;
    --secondary: #000;
    --negative: #ff3d4d;
    --warning: #ffa64d;
    --success: #3ac270;


    // переменные для контейнера
    --padding-primary: 1.25rem;
    --border-radius: 0.8rem;

    // font
    // font-size + line-height + font-family
    --font-primary: 1rem/1.5rem 'Montserrat', sans-serif;
}



.c__primary {
    color: var(--ac-primary);
}

// Container
.container {
    max-width: ($containerWidth + $containerPadding) + px;
    width: 100%;
    margin: 0 auto;
    padding: 0 $containerSidePadding + px;

    @media (max-width: $breakpoint1450) {
        max-width: 1200px;
    }

    @media (max-width: $breakpoint1200) {
        max-width: 1140px;
    }

    @media (max-width: $breakpoint992) {
        max-width: 970px;
    }

    @media (max-width: $breakpoint768) {
        max-width: 735px;
    }

    @media (max-width: $breakpoint480) {
        max-width: none;
    }

    @media (max-width: $breakpoint375) {
        max-width: none;
    }

    @media (max-width: $breakpoint300) {
        max-width: none;
    }
}

// Body Locked
body {
    font-family: 'Montserrat', sans-serif;
    font-family: 'Hyperspace Race Expanded', sans-serif;
    background-color: #fff;

    &.locked {
        overflow: hidden;
    }

    background-color: #121822;

    max-width: 640px;
    margin: 0 auto;

    #root {
        padding: 0 var(--padding-primary);
        padding-top: 0.8rem;
        background-color: var(--bg-primary);
        color: var(--fg-primary);
        position: relative;
        display: flex;
        min-width: 100%;
        align-items: stretch;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: stretch;
        justify-content: flex-start;
        min-height: 100vh;
        border-radius: var(--border-radius);
        transition: all ease-out .2s;

        &.small {
            transform: scale(0.97) // translateY(1.5%);
        }

        // убрать возможность выделения текста
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

    }
}



// Animations
// Fade
@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@font-face {
    font-family: 'Hyperspace Race Expanded';
    src: url('../fonts/Fontspring-DEMO-hyperspacerace-bold.otf');
}

.section__title {

    @media (max-width: $breakpoint480) {
        font-size: 30px !important;
    }
}


.swiper-pagination6 {
    z-index: 999 !important;
    position: relative !important;
    bottom: -35px !important;
    width: 100% !important;
    left: 0 !important;

    display: flex;
    justify-content: center !important;

    @media (max-width: $breakpoint1200) {
        bottom: -25px !important;
    }

    @media (max-width: $breakpoint768) {
        bottom: -25px !important;
    }

    @media (max-width: $breakpoint480) {
        bottom: -25px !important;
    }

}

.swiper-pagination7 {
    z-index: 999 !important;
    position: relative !important;
    bottom: -35px !important;
    width: 100% !important;
    left: 0 !important;

    display: flex;
    justify-content: center !important;

    @media (max-width: $breakpoint1200) {
        bottom: -25px !important;
    }

    @media (max-width: $breakpoint768) {
        bottom: -25px !important;
    }

    @media (max-width: $breakpoint480) {
        bottom: -25px !important;
    }

}

.swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
    background: #fff !important;
    border: 1px solid #000 !important;
}

.swiper-pagination-bullet-active {
    opacity: 1 !important;
    background: #76FCBC !important;
}

li {
    list-style: none;
}

a,
a:visited {
    text-decoration: none;
}

*,
:after,
:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

* {
    padding: 0;
    margin: 0;
    border: 0;
}

.withPaggin {
    display: flex !important;
    flex-direction: column;
    gap: 2rem;
}