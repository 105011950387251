.InfoBlockP {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    row-gap: 1.5rem;
    margin-top: 3rem;

    .InfoBlock {
        padding: 1rem;
        border-radius: var(--border-radius);
        background-color: var(--bg-secondary);
        row-gap: 0.6rem;
        display: grid;
        // 3 колонки
        grid-template-columns: 50px auto auto;
        column-gap: 1rem;
        transition: background-color .2s ease-in-out;
        align-items: center;

        &:active{
            background-color: var(--bg-secondary-active);
            
        }

        .icon {
            .ICON {
                display: flex;
                border-radius: 10rem;
                width: 50px;
                height: 50px;
                align-items: center;
                justify-content: center;
                align-content: center;
                font-size: 1.8rem;
            }
        }

        .desc {
            display: flex;
            // column
            flex-direction: column;
            flex-wrap: nowrap;
            row-gap: .15rem;

            .title {
                font-size: 1.2rem;
                font-family: 'Montserrat', sans-serif;
                color: var(--fg-primary);
            }

            .desc {
                color: var(--fg-secondary);
                font-size: .85rem;
                font-weight: 200;
                font-family: sans-serif;
            }

        }

        .data {
            // прикрепляем к правому краю
            justify-self: end;
            font-family: 'Montserrat', sans-serif;
            color: var(--fg-primary);
        }
    }
}