.Err404 {
    width: 100%;
    height: 88vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Courier New', Courier, monospace;

    .Data{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        // row-gap: .2rem;
        .title{
            font-size: 5rem;
            font-weight: 600;
        }
        .desc{
            max-width: 70%;
            font-size: 1.5rem;
            font-weight: 600;
        }
    }
}