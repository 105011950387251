@use 'sass:color';
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@700&display=swap");

// Добавить grayscale() в scss
@mixin grayscale_element($value) {
    -webkit-filter: #{"grayscale(#{$value})"};
-moz-filter: #{"grayscale(#{$value})"};
filter:#{"grayscale(#{$value})"};

}


.StarIcon {
    overflow:visible;
    --button-star-greyscale: 100%;
    --button-star-contrast: 0%;
    // pointer-events: none;
    // убрать возможность выделения текста
    user-select: none;
    // убрать выделение при долгом нажатии
    -webkit-tap-highlight-color: transparent;
    
    // убрать взаимодействие по ПКМ
    -webkit-touch-callout: none;

    button {
        appearance: none;
        border: none;
        cursor: pointer;
        background-color: #fff;
        color: #5e5e5e;
        border-radius: 36px;
        outline: none;
        margin: 0;
        padding: 0;
        padding-left: 90px;
        font-family: "Montserrat Alternates";
        font-size: 42px;
        letter-spacing: -2px;
        font-weight: 600;
        line-height: 40px;
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.05),
            inset 0px -10px 20px 0px rgba(0, 0, 0, 0.05),
            0px 10px 20px 0px rgba(0, 0, 0, 0.05);
    }

    .icon {
        display: block;
        width: 600px;
        height: 300px;
        z-index: 1;
        pointer-events: none;
        transform-origin: 50% 52%;
        filter: contrast(var(--button-star-contrast));
        @include grayscale_element(var(--button-star-greyscale));
        opacity: 0.3;
        position: absolute;
        top: -100px;
        left: -240px;
    }

    .icon canvas {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .label {
        width: 140px;
        padding: 20px 0 22px;
        transform: translateZ(0);
    }

    .default {
        display: block;
    }

    .number {
        padding: 30px 36px;
        position: relative;
        transform: translateZ(0);
    }

    .number:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background-color: #e8e8e8;
        opacity: 0.4;
    }

    .current {
        color: #d9d9d9;
        opacity: 1;
        display: block;
    }

    .new {
        color: #3de150;
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        display: block;
    }

    .add {
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        opacity: 0;
        transform: translateY(38px);
        pointer-events: none;
        color: #d0d0db;
        display: block;
    }
}