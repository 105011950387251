@use 'sass:math';
$containerWidth: 1150;
$containerPadding: 40;
$containerSidePadding: math.div($containerPadding, 2);
$breakpoint1450: 1449.98+px;
$breakpoint1200: 1199.98+px;
$breakpoint992: 991.98+px;
$breakpoint768: 767.98+px;
$breakpoint480: 479.98+px;
$breakpoint375: 374.98+px;
$breakpoint300: 299.98+px;

// Burger Menu
$burgerBreakpoint: $breakpoint992;
$burgerColor: black;
$burgerActiveColor: black;

// Fonts
$fontMain: sans-serif;
$fontSecondary: '';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;1,200&family=Oswald:wght@700&display=swap');



// Navbar
nav {
    overflow: hidden;
}

.navbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0px;

    @media (max-width: $breakpoint768) {
        padding: 20px 0px;
    }


    &__button {
        padding: 12px 65px;
        background: #1E1E1E;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.005em;
        color: #FFFFFF;

        display: flex;
        align-items: center;
        gap: 8px;
        transition: all ease-out .3s;

        & img {
            transition: all ease-out .3s;
        }

        &:hover {
            transition: all ease-out .3s;

            & img {

                transition: all ease-out .3s;
                transform: translateX(15px);
            }

        }

        @media (max-width: $breakpoint992) {
            display: none;
        }

    }

}

.menu {
    display: flex;
    align-items: center;
    gap: 40px;

    // .menu__item-link
    &__item {

        // background-color: #242424;
        &-link {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.005em;
            transition: all ease .3s;
            color: #242424;

            &:hover {
                color: #81b245;
            }

        }

        &-btn {
            padding: 12px 65px;
            background: #1E1E1E;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.005em;
            color: #FFFFFF;


            display: flex;
            align-items: center;
            gap: 8px;
            transition: all ease-out .3s;

            & img {
                transition: all ease-out .3s;
            }

            display: none;

            &:hover {
                transition: all ease-out .3s;

                & img {

                    transition: all ease-out .3s;
                    transform: translateX(15px);
                }

            }


            @media (max-width: $breakpoint992) {
                display: flex;
            }

        }
    }

}

.burger {
    display: none;
    cursor: pointer;
}

// Burger menu styles
@media screen and (max-width: $burgerBreakpoint) {

    // .menu
    .menu {
        position: fixed;

        background-color: #fff;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100vw;
        display: none;

        &.active {
            display: flex;
            left: 0;
            top: 0;
            height: 100vh;
            width: 100vw;
            flex-direction: column;
            pointer-events: all;
            justify-content: center;
            align-items: center;
            z-index: 22;
            animation: fade 0.1s linear;
        }
    }

    // .burger
    .burger {
        display: block;
        position: relative;
        height: 20px;
        width: 30px;
        z-index: 102;
        cursor: pointer;

        &.active-burger {
            &:before {
                background: $burgerActiveColor;
                transform: rotate(45deg);
                transition: cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s all;
                top: 8px;
            }

            &:after {
                background: $burgerActiveColor;
                transform: rotate(-45deg);
                bottom: 10px;
                transition: cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s all;
            }

            & span {
                transform: scale(0);
            }
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            height: 2px;
            width: 100%;
            background: $burgerColor;
            transition: cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s all;
        }

        &:before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }

        span {
            position: absolute;
            top: 9px;
            right: 0;
            width: 100%;
            background: $burgerColor;
            height: 2px;
            transform: scale(1);
            transition: cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s all;
        }
    }

    // .menu__item
    .menu__item {
        margin-top: 15px;
    }

    // .menu__link
    .menu__item-link {
        color: #1E1E1E;
    }
}