.PaymentsPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;

    &-transactions {
        margin-top: 2rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: auto;
        padding: 20px 0px;
        box-sizing: border-box;

        .transaction {
            font-family: monospace;
            padding: 0.6rem 1rem;
            border-radius: var(--border-radius);
            margin-top: 1rem;
            background-color: var(--bg-quaternary);
            display: grid;
            grid-template-columns: max-content auto auto auto auto 0.2fr;
            column-gap: 0.4rem;

            align-items: center;
            justify-items: center;
            align-content: center;

            // transaction
            // все дивы кроме последнего
            & div:not(:last-child) {
                // border-right: 1px solid var(--fg-primary);
            }

            .url {
                color: var(--ac-primary);
                font-size: 1rem;
            }

            .status {
                font-size: 1rem;
            }
        }
    }
}