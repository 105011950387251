.WalletPanel {
    width: 70%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 2rem;
}



@keyframes blink {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}