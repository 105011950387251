.QRScanner {
    text-align: right;
    &-panel {
        text-align: center;
        width: 100%;
        top: 2vh;
        height: 98vh;
        display: flex;
        position: absolute;
        left: 0;
        background-color: #000;
        transition: all ease-out .3s;
        transform: translateY(100%);

        &.active {
            display: flex;
            transform: translateY(0) scale(1.03);
        }



        .context {
            width: 100%;
            padding: 0 var(--padding-primary);
            padding-top: 10px;

            .header {
                display: flex;

                .exitBtn {
                    padding: 1rem;
                    width: 2rem;
                    height: 2rem;
                    background-color: var(--bg-secondary);
                    color: #fff;
                    font-size: 1.5rem;
                    cursor: pointer;
                    border-radius: 100%;
                }
            }
        }
    }

    &-btn {
        width: 2.4rem;
        height: 2.4rem;
        background-color: var(--bg-secondary);
        color: #fff;
        font-size: 1.2rem;
        cursor: pointer;
        border-radius: 100%;

        &:hover {
            // затемнение 
            filter: brightness(0.85);
        }
    }
}