@use 'sass:math';
$containerWidth: 1150;
$containerPadding: 40;
$containerSidePadding: math.div($containerPadding, 2);
$breakpoint1450: 1449.98+px;
$breakpoint1200: 1199.98+px;
$breakpoint992: 991.98+px;
$breakpoint768: 767.98+px;
$breakpoint480: 479.98+px;
$breakpoint375: 374.98+px;
$breakpoint300: 299.98+px;

// Burger Menu
$burgerBreakpoint: $breakpoint992;
$burgerColor: 'black';
$burgerActiveColor: 'black';

// Fonts
$fontMain: sans-serif;
$fontSecondary: '';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;1,200&family=Oswald:wght@700&display=swap');

.MainPage {
    &-header {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        background-color: var(--bg-primary);
        display: grid;
        // 3 колонки
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        text-align: center;
        border-radius: var(--border-radius);


        position: fixed;
        width: 100%;
        left: 0px;
        top: 0;
        padding: 1rem var(--padding-primary);
        transition: all ease-out .15s;

        &.active {
            // добавить тень снизу белую
            box-shadow: 0px 1px 20px rgba(203, 203, 203, 0.05);
        }

        .right {
            text-align: right;
        }

    }

    &-balance {
        padding-top: 6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 0.4rem;

        span {
            font-family: 'Courier New', Courier, monospace;
            font-size: 1rem;
            color: var(--fg-secondary);
        }
    }
}