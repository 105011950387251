.help__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    padding-top: 1rem;

    .help__description {
        text-align: center;
        color: var(--textSecondary);
        display: block;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -.08px;
        line-height: 20px;
        margin-bottom: 4px;
        padding: 0 12px;
    }

    .help__icon-wrapper {
        gap: 0.6rem;
        align-items: center;
        display: flex;

        .help__icon {
            font-size: 2rem;
            background-color: white;
            align-items: center;
            background: var(--backgroundApp);
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            height: 44px;
            justify-content: center;
            width: 44px;
            color: white;
            transition: all .3s ease;

            &:hover {
                color: var(--backgroundApp);
                background-color: white;

            }

            &:active {
                transform: scale(.9);
            }
        }
    }
}