.expire_container {
    padding: 8px 32px;
    
    @media (max-width: 768px) {
        border-bottom: 1px solid var(--borderPrimarySolid);
    }
    
    @media (min-width: 768px) {
        padding: 8px;
        border-right: 1px solid var(--borderPrimarySolid);
        display: flex;
    }

    .expire {
        align-items: center;
        display: flex;
        background-color: var(--backgroundExpiration);
        border-radius: 16px;
        padding: 16px;
        width: max-content;

        &__icon {
            align-items: center;
            display: flex;
        }

        &__progress {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 16px;
            // height: 20px;
        }

        &__label {
            color: var(--contentSecondary);
        }

        &__time {
            color: var(--success);
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            width: 72px;
            font-family: 'Courier New', Courier, monospace;
        }
    }
}